// Usually compass hacks apply to both ie6 & 7 -- set this to false to disable support for both.
$legacy-support-for-ie: true !default;

// Setting this to false will result in smaller output, but no support for ie6 hacks
$legacy-support-for-ie6: $legacy-support-for-ie !default;

// Setting this to false will result in smaller output, but no support for ie7 hacks
$legacy-support-for-ie7: $legacy-support-for-ie !default;

// Setting this to false will result in smaller output, but no support for legacy ie8 hacks
$legacy-support-for-ie8: $legacy-support-for-ie !default;

// @private
// The user can simply set $legacy-support-for-ie and 6, 7, and 8 will be set accordingly,
// But in case the user set each of those explicitly, we need to sync the value of
// this combined variable.
$legacy-support-for-ie: $legacy-support-for-ie6 or $legacy-support-for-ie7 or $legacy-support-for-ie8;

// Whether to output legacy support for mozilla.
// Usually this means hacks to support Firefox 3.6 or earlier.
$legacy-support-for-mozilla: true;

// Support for mozilla in experimental css3 properties (-moz).
$experimental-support-for-mozilla      : true !default;
// Support for webkit in experimental css3 properties (-webkit).
$experimental-support-for-webkit       : true !default;
// Support for webkit's original (non-standard) gradient syntax.
$support-for-original-webkit-gradients : true !default;
// Support for opera in experimental css3 properties (-o).
$experimental-support-for-opera        : true !default;
// Support for microsoft in experimental css3 properties (-ms).
$experimental-support-for-microsoft    : true !default;
// Support for khtml in experimental css3 properties (-khtml).
$experimental-support-for-khtml        : false !default;
// Support for svg in experimental css3 properties.
// Setting this to true might add significant size to your
// generated stylesheets.
$experimental-support-for-svg          : false !default;
// Support for CSS PIE in experimental css3 properties (-pie).
$experimental-support-for-pie          : false !default;
