/* CSS Document - SASS */
/* Front-End */


/* 
NEW - CSS VARIABLES 
https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
:root {
  --main-bg-color: brown;
}
.one {
  background-color: var(--main-bg-color);
}
*/



// MATERIAL DESIGN COOR PICKER TOOLS !!!!!!!!!!!!!!!!!!!!!!!!!!!!

/* 
 * SASS - VARIABLES - Colors 
 */
$c_primary: #999; // FF5722
$c_primary_dark: #E64A19; 
$c_primary_light: #FFCCBC; 

$c_accent: #03A9F4;

$c_white: #fff; /* white */
$c_text: #212121;
$c_text_2: #727272;
$c_divider: #B6B6B6;



$c_black: #000; /* white */

$c_bg_gray: #626365; // footer, etc 

$c_bg_yellow: #FED100; // Sectors (refernces box)





/* FEND */

$c_blue: #10069F;
$c_grey: #ECE8E4;

/* 
 * VARIABLES - Font size 
 */
$fs_xxl: 34px;
$fs_xl: 28px;
$fs_l: 20px;
$fs_m: 16px;
// $fs_s: 12px;
$fs_s: 14px;



/* 
 * FALLBACKS using Sass
 */
 
 

/* CREATE MIXIN for rem fontsize and px fallback solution */

/* FALLBACK for background opacity */
	#asfgasafasf {
		background: #ccc; /* Fall-back for brosers that don't support opacity */
		background: rgba(204, 204, 204, 0.85);

	}



// create mixin - CSS_FALLBACK_tricks - LINK - the RGB fallback color only works when using shorthand. 
// http://css-tricks.com/ie-background-rgb-bug/







%ui_underline_simple {	
	// cursor: pointer; // UI - for when class is applied to non-anchor
	position: relative;			
	
	&::after {
		content: '';

		height: 2px; // TEST 
    // background-color: red;
		background-color: none;
		
		width: 100%; 
		position: absolute;
		left: 0px;
		bottom: -8px;
		@include transition(background-color, 0.2s);
		}
	
	// hacerlo comn onmouseneter & onmouseleave de jqwuery, no con ekl hover porque hay glitch !!!!
	/*
    &:hover::after {
		bottom: -6px; // hace width gllitch... 
		height: 3px; 
		}	
    */
    &:hover::after {
		background-color: $c_white;
		}	

	} // %ui_underline



















