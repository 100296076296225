@import "shared";

// Change the box model for Mozilla, Webkit, IE8 and the future
//
// @param $bs
//   [ content-box | border-box ]

@mixin box-sizing($bs) {
  $bs: unquote($bs);
  @include experimental(box-sizing, $bs,
    -moz, -webkit, not(-o), not(-ms), not(-khtml), official
  );
}
