@import "shared";

// Mixins to support specific CSS Text Level 3 elements
//
//
//
// Mixin for word-break properties
// http://www.w3.org/css3-text/#word-break
// * legal values for $type : normal, keep-all, break-all
//
// Example:
//    p.wordBreak {@include word-break(break-all);}
//
// Which generates:
//    p.wordBreak {
//      -ms-word-break: break-all;
//      word-break: break-all;
//      word-break: break-word;}
//
@mixin word-break($value: normal){
  @if $value == break-all {
    //Most browsers handle the break-all case the same...
    @include experimental(word-break, $value,
      not(-moz), not(-webkit), not(-o), -ms, not(-khtml), official
    );
    //Webkit handles break-all differently... as break-word
    @include experimental(word-break, break-word,
      not(-moz), not(-webkit), not(-o), not(-ms), not(-khtml), official
    );
  }
  @else {
    @include experimental(word-break, $value,
      not(-moz), not(-webkit), not(-o), -ms, not(-khtml), official
    );
  }
}

// Mixin for the hyphens property
//
// W3C specification: http://www.w3.org/TR/css3-text/#hyphens
// * legal values for $type : auto, manual, none
//
// Example:
//  p {
//    @include hyphens(auto);}
// Which generates:
//  p {
//    -moz-hyphens: auto;
//    -webkit-hyphens: auto;
//    hyphens: auto;}
//
@mixin hyphens($value: auto){
  @include experimental(hyphens, $value,
    -moz, -webkit, not(-o), not(-ms), not(-khtml), official
  );
}

// Mixin for x-browser hyphenation based on @auchenberg's post:
// Removes the need for the <wbr/> HTML tag
// http://blog.kenneth.io/blog/2012/03/04/word-wrapping-hypernation-using-css/
//
//  Example:
//  div {@include hyphenation;}
//
//  Which generates:
//    div {
//      -ms-word-break: break-all;
//      word-break: break-all;
//      word-break: break-word;
//      -moz-hyphens: auto;
//      -webkit-hyphens: auto;
//      hyphens: auto;}
//
@mixin hyphenation{
  @include word-break(break-all);
  @include hyphens;
}
