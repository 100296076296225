/*
 * Project: elcatala.cat
 * Area: Front
 * File: TYPOGRAPHY - HTML-EDITOR
 */	
	
/*
<h1>Header 1</h1>
<h2>Header 2</h2>
<h3>Header 3</h3>
<h4>Header 4</h4>
<h5>Header 5</h5>
<h6>Header 6</h6>
*/

	
// que esto sea la que haga funcionar al styling de typography !!!!! tutto in uno !!! yiha !!!	



.m_htmleditor { // ESTO SOLO ES PARA LO QUE VENGA DINAMICAMENTE DE LA BBDD !!!!!! - en este caso, deberia ser s_baseTypography or s_typographyBase


blockquote {
	color: $c_blue;
	font-size: 21px;
	line-height: 2.3rem;
	@include f_main_light;
	font-style: italic; // TODO CREATE EL ITALIC FIEL 
	// @include f_main_light_italic; // wasnt working, dunno
	
	// RESET
	margin: 0px; // RESET
	
	margin-top: $spacing2; // HACKY !!!!!!!!!!!!!!! TODO
	margin-bottom: $spacing2; // TEST ALL SAME SAME

	@include paddingh($spacing4); // TEST
	
	
	@media only screen and (max-width: $bp_s) { 	
		@include paddingh($spacing); // TEST
		} /* MEDIA-QUERY */	
				
				
					
	text-align: center;
	
	&::before {
		content: '«';		
		}
	&::after {
		content: '»';		
		}
		
	p {
		display:inline; // NEEDED DUE TO WP CMS
		}	

	} // blockquote



    /*
		&.case_dark {
			// background-color: $c_black_2; // TEMP
			background-color: #252525;
			color: $c_white;
			a {color: inherit;} // TEST
			}
		*/
		
		/*	
    h2 { // se usa en legalpages !!! was
        font-size: 28px;
        margin-top:24px;
        margin-bottom:24px;
        }
							
    h3 { // se usa en legalpages !!! was
        font-size: 20px;
        // color: #f00;
        margin-top:24px;
        // margin-bottom:12px;
				margin-bottom:6px;
        // color: $c_blue;
        }
    */
		
		
		
		@include f_main_light;
		font-size: 17px; 
		line-height: 1.5rem; 
		
		
		
		h1, h2, h3, h4, h5, h6 {
			color: $c_blue;
			// @include f_main;
			@include f_aside;
			} // AL HEADERS
		
		
    h1 {
        font-size: 32px;
        margin-top: $spacing;
        margin-bottom: $spacing;
        }
						
    h2 { // tb se usa en legalpages !!!
        font-size: 23px;
				
        margin-top: $spacing2;
        margin-bottom: $spacing05;
        }
							
    h3 {
        font-size: 24px;
        margin-top:24px;
				margin-bottom:6px;
				margin-bottom: $spacing05;
        }
    
    h4 {
        font-size: 20px;
        margin-top:24px;
        margin-bottom:36px;
				margin-bottom: $spacing05;
        }
				
    h5 {
        font-size: 18px;
        margin-top:24px;
        margin-bottom: $spacing025;
        }
				
    h6 {
        font-size: 16px;
        margin-top:24px;
        margin-bottom: $spacing025;
        }
			
		
		
		
		
		@include f_aside_light;
				
    p {
		
		
		@include f_aside_light;
		
		
		
			// margin-bottom: 12px;
			// margin-bottom: $spacing;
			margin-bottom: $spacing05;
			&:last-of-type {margin-bottom: 0px;}
			
			// padding-bottom: 12px; // LAST OF TYPE...
			
			&.bold {
				// @extend .ProximaNova-Semibold;
				} // bold
				
			&:first-of-type {
				margin-bottom: $spacing2; // TEST, same same
				/* TEXT */
				@include f_aside;
				font-size: 22px; // TEST
				line-height: 1.9rem; // TEST
				} // first	
			
			} // p
    
		
		
		
		
    a {
			// color: $c_black_grey; // test 
			font-weight: bold; // HMMMMMMM
			&:hover {text-decoration: underline;}
			} // a
    

		/*
		 * LISTS
		 */
    ul {
			margin-left:24px;
			// margin-bottom: 12px;
			margin-bottom: 24px;
			list-style:  disc;
			// list-style-image: url('../assets/img/bullet_blue.jpg'); /* deberia ser un gif */
			}
			
			li {
				// margin-bottom: 18px;
				// margin-bottom: 12px;
				margin-bottom: 10px;
				}   
			
				li ul {
					margin-top: 10px;
					}  	
	
	
		/*
		 * MEDIA INSERTADO EN EL HTMLEDITOR - guardar esto, esto es para el contenido dinamico de la bbdd, almenos 1 solo tipo en cada web, quizás más.... hmm, ok.
		 */
	
		img {
			@include img;
			// @include marginv($spacing);	
			@include marginv($spacing2);	
			
			
			@media only screen and (max-width: $bp_s) { 	
				// margin-left: -$spacing; // va
				margin-right: -$spacing; // HACKY TEST // pero esto no va... wtf 
				} /* MEDIA-QUERY */	
		
								
			}	// img
		
		iframe { // TARGET IT WITH FITVIDS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			width: 100%;
			// @include marginv($spacing);
			@include marginv($spacing2);	
			}
	
    } // m_htmleditor





/*
.m_link { // solo usado en email_enviado
 	margin-top: $spacing; // TEST
	
	// font-size: 36px;
	// text-align: center;

	a {
		// display: inline-block;
		// display: block;
		display: inline-block; // para que no sea fullwidth !!!
		@extend .buttonMain;
	} // a

	
	// centrar el boton
	@media only screen and (max-width: $bp_s) {
		text-align: center;		
		} // MEDIA-QUERY	
	
} // link
*/


	
	