/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Reset
 * File: Layout
 */

// 1 - BACKGROUND COLORS

body {
	// background-color: $c_white; 
  }

#page {
	/* PLACEHOLDER */
	}
	
header {
	background-color: $c_blue;  
	}
footer {
	background-color: $c_blue;  
	}		
		

	
body {
	// font-family: ;
	// color: #fff;
  }	

			
// ???? WHAT IT IS REALLY NEEDED FOR... JUST IN CASE...
		





// X - WIDTHS

		
.frame { 
	@include centered;
	/* max-width:1300px; */
	// max-width:1140px;
	max-width:1440px;
	max-width:1342px; // FRAME MATCHES WIDTH OF PDF AT 61,6%
	min-width:320px; 
	// @include paddingh($spacing);	
	@include paddingh($spacing2);	
	@include transition(padding-left, $t_slow);
	@include transition(padding-right, $t_slow);
	
	@media only screen and (max-width: $bp_s) { 
		// @include paddingh($spacing05);	
		@include paddingh($spacing);					
		} // MEDIA-QUERY	

  } // frame 			
	

.frame_centered {
	max-width: 800px;
	@include centered;
	} // frame_centered


.frame_to_flush { 
	@include centered;
	/* max-width:1300px; */
	// max-width:1140px;
	// max-width:1440px;
	max-width:1342px; // 
	min-width:320px; 
	// @include paddingh($spacing);	
	@include paddingh($spacing2);	
	@include transition(padding-left, $t_slow);
	@include transition(padding-right, $t_slow);
	
	@media only screen and (max-width: $bp_s) { 
		// @include paddingh($spacing05);	
		@include paddingh(0px);					
		} // MEDIA-QUERY	

  } // frame 		










.frameWide { /* HEADER .. */
	@include centered;
	/* max-width:1300px; */
	max-width:1920px;
	min-width:320px; 
	@include paddingh($spacing);	
	@include transition(padding-left, $t_slow);
	@include transition(padding-right, $t_slow);
	
	
	@media only screen and (max-width: $bp_m) { 
		@include paddingh($spacing05);					
		} // MEDIA-QUERY	

	
  } // frameWide 







section {
	// background-color: #CCC; 
	// margin-bottom: $spacing2; // TEST
	
	
	&.bgGrey { 
	 	background-color: $c_grey; 
	  } 
		
		
		
		
		
		
	
	&.fullHeightTest { 
	 	background-color: #f06; 
		// height: 100%;
		height: 100vh;
	  } // 
	
	
	&.halfHeightTest { 
	 	background-color: #E8E8E8; 
		// height: 100%;
		height: 50vh;
	  } // 
	
	
	&.test {
		border-bottom: 3px solid black;
		@include paddingv($spacing2); // TEST
		
		background-color: #ccc; 
		&:nth-of-type(odd) {background-color: #999;}
		} // test
	
	
	} // section
	
	
	
// CREATE STH FOR GENERIC PAGE CNTENT TOP SPACE, AND BOTTOM SPACE, AS AVARIABLE SOMEHOW... OR AS A DIV DUMMY IN BOTH EXTREMES	
	
	
	
	
	
	
.l_fullpageImage { // vs just above the fold...
	//
	border: 3px solid #f06;
	height: 100vh;
	
	
	} // l_fullpageImage
	
	
	

#content { // 
	// min-height:200px; 
	
	// background-color: #999;
	/*
	overflow:hidden; // ????????? 
	@include paddingv($spacing2);
	*/
	
	// VIP TEMP
	// padding-top: 200px; // TESTheadroom  - por lo del header hedroom etc ?!?!?!! - check with javascript, or with position sticky !!!!!
	
	
	.inner {}
	
	
		} // content


	
	
	

.m_pageTitle {
	/* LAYOUT */
	// background-color: $c_accent; // TEST
	@include paddingv($spacing); // ---  padv vs padh & marv vs marh
	
	/* TEXT */
	font-size: 48px;
	line-height: 58px;
	} // m_pageTitle










// .m_





// HTML5 TAGS - main, article, etc 
// https://www.tutorialrepublic.com/html-reference/html5-tags.php
// ARTICLE
// https://www.tutorialrepublic.com/html-reference/html5-article-tag.php
		
		
		
		
			
@media only screen and (max-width: $bp_l) { 

		
						
} // MEDIA-QUERY			

@media only screen and (max-width: $bp_m) { // change to 767px ???

	
									
} // MEDIA-QUERY

@media only screen and (max-width: $bp_s) { 

		
					
} // MEDIA-QUERY