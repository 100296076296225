@import "shared";

// Specify the shorthand `columns` property.
//
// Example:
//
//     @include columns(20em 2)
@mixin columns($width-and-count) {
  @include experimental(columns, $width-and-count,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the number of columns
@mixin column-count($count) {
  @include experimental(column-count, $count,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the gap between columns e.g. `20px`
@mixin column-gap($width) {
  @include experimental(column-gap, $width,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the width of columns e.g. `100px`
@mixin column-width($width) {
  @include experimental(column-width, $width,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the width of the rule between columns e.g. `1px`
@mixin column-rule-width($width) {
  @include experimental(column-rule-width, $width,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the style of the rule between columns e.g. `dotted`.
// This works like border-style.
@mixin column-rule-style($style) {
  @include experimental(column-rule-style, unquote($style),
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Specify the color of the rule between columns e.g. `blue`.
// This works like border-color.
@mixin column-rule-color($color) {
  @include experimental(column-rule-color, $color,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Mixin encompassing all column rule properties
// For example:
//
//     @include column-rule(1px, solid, #c00)
//
// Or the values can be space separated:
//
//     @include column-rule(1px solid #c00)
@mixin column-rule($width, $style: false, $color: false) {
  $full : -compass-space-list(compact($width, $style, $color));
  @include experimental(column-rule, $full,
    -moz, -webkit, -o, -ms, not(-khtml), official
  );
}

// Mixin for setting column-break-before
//
// * legal values are auto, always, avoid, left, right, page, column, avoid-page, avoid-column
//
//  Example: 
//    h2.before {@include column-break-before(always);}
//
//  Which generates: 
//
//  h2.before {    
//    -webkit-column-break-before: always;
//    column-break-before: always;}
@mixin column-break-before($value: auto){
   @include experimental(column-break-before, $value, not(-moz), -webkit, not(-o), not(-ms), not(-khtml), official );
}

// Mixin for setting column-break-after
//
// * legal values are auto, always, avoid, left, right, page, column, avoid-page, avoid-column
//
//  Example: 
//    h2.after {@include column-break-after(always); }
//
//  Which generates: 
//
//  h2.after {
//    -webkit-column-break-after: always;
//    column-break-after: always; }
@mixin column-break-after($value: auto){
   @include experimental(column-break-after, $value, not(-moz), -webkit, not(-o), not(-ms), not(-khtml), official );
}

// Mixin for setting column-break-inside
//
// * legal values are auto, avoid, avoid-page, avoid-column
//
//  Example: 
//    h2.inside {@include column-break-inside();}
//  Which generates: 
//  
//  h2.inside {
//    -webkit-column-break-inside: auto;
//    column-break-inside: auto;}
@mixin column-break-inside($value: auto){
   @include experimental(column-break-inside, $value, not(-moz), -webkit, not(-o), not(-ms), not(-khtml), official );
}

// All-purpose mixin for setting column breaks.
//
// * legal values for $type : before, after, inside 
// * legal values for '$value' are dependent on $type
//    * when $type = before, legal values are auto, always, avoid, left, right, page, column, avoid-page, avoid-column
//    * when $type = after, legal values are auto, always, avoid, left, right, page, column, avoid-page, avoid-column
//    * when $type = inside, legal values are auto, avoid, avoid-page, avoid-column
//  
//  Examples: 
//    h2.before {@include column-break(before, always);}
//    h2.after {@include column-break(after, always); }
//    h2.inside {@include column-break(inside); }
//
//  Which generates: 
//  h2.before {    
//    -webkit-column-break-before: always;
//    column-break-before: always;}
//  
//  h2.after {
//    -webkit-column-break-after: always;
//    column-break-after: always; }
//
//  h2.inside {
//    -webkit-column-break-inside: auto;
//    column-break-inside: auto;}
 
@mixin column-break($type: before, $value: auto){
   @include experimental("column-break-#{$type}", $value, not(-moz), -webkit, not(-o), not(-ms), not(-khtml), official );
}