@import "shared";

// The default value is `padding-box` -- the box model used by modern browsers.
//
// If you wish to do so, you can override the default constant with `border-box`
//
// To override to the default border-box model, use this code:
//     $default-background-clip: border-box

$default-background-clip: padding-box !default;

// Clip the background (image and color) at the edge of the padding or border.
//
// Legal Values:
//
//   * padding-box
//   * border-box
//   * text

@mixin background-clip($clip: $default-background-clip) {
  // webkit and mozilla use the deprecated short [border | padding]
  $clip: unquote($clip);
  $deprecated: $clip;
  @if $clip == padding-box { $deprecated: padding; }
  @if $clip == border-box { $deprecated: border; }
  // Support for webkit and mozilla's use of the deprecated short form
  @include experimental(background-clip, $deprecated,
    -moz,
    -webkit,
    not(-o),
    not(-ms),
    not(-khtml),
    not official
  );
  @include experimental(background-clip, $clip,
    not(-moz),
    not(-webkit),
    not(-o),
    not(-ms),
    -khtml,
    official
  );
}
