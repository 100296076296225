/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Reset
 * File: Header
 */


#w_headroom {
	
	} // w_headroom

	
	
	
header {
	/*
	position: absolute; top: 0px; // TEMP HACKY TODO 
	width: 100%;
	*/
	
	
	.inner {
		// padding: $spacing025; 
		// @include paddingv($spacing05);
		@include paddingv($spacing);
		// padding-top: $spacing2; // TEST
		// overflow:hidden; // S&D WITH PSPAD - OVERLL - DEV FILE TASKS
		@extend .clearfix; // VIP ...
		position:relative;
		
	
		/* FLEX */
		display: flex;
		align-items: center; // VERTICAL ALIGNMENT // OK
		// justify-content: center; // OK, IT WORKS
		justify-content: flex-end; // OK, IT WORKS
		justify-content: flex-start; // OK, IT WORKS
		
		justify-content: space-between; // NOT DOING IT
		
		// justify-content: space-evenly; // ??? 
		
	
	.item {
		
			&:nth-of-type(1) {
				width: 15%;
				}
			
			&:nth-of-type(2) {
				width: 70%;
				}
			
			&:nth-of-type(3) {
				width: 15%;
				}
				
				
								
		} // item
	


	@media only screen and (max-width: $bp_m) { 
		
		align-items: flex-end; // VERTICAL ALIGNMENT // OK
		
		
		
		justify-content: flex-start;
		
		.item { // temp, just do it 
			width: auto !important; // RESET FOR ALL 3
	
			&:nth-of-type(1) {
				margin-right: $spacing075; // TEST
				}
			
			&:nth-of-type(2) {
				order: 3;
				margin-left: auto;
				}
			
			&:nth-of-type(3) {
				order: 2;
				}
				
			} // item
		
							
		} // MEDIA-QUERY	
			
			
			
			
			
			

		} // inner
	} // header
	


















.logoMain_mobile {
	float:left;
	width: 140px;
	img {@include img;}
	} // logoMain



	
.logoMain_shape {
	float:left;
	width: 40px;
	img {@include img;}
	} // logoMain
			
	
.logoMain_text {
	float:right;
	width: 100px;
	img {@include img;}
	} // logoMain			
			
		
		
	
nav.main { // COULD FIX SOME FLEX ... 
	text-align: center;
	font-size: 0px;	
	/* level 1 */
	ul { /* UL first level */
		display: inline-block;
		@extend .clearfix; /* FLOAT */
		}
	
	li { /* LI first level */
		float: left;
	
		&:first-of-type {
			// float: left;
			// margin-right: $spacing4; // TEST
			margin-right: $spacing6; // TEST
			}
		&:last-of-type {
			// float: right;
			}
	
	
		// border: 1px solid black;
		// @extend %ui_underline_simple;
	
	
		/*
		margin-right: $spacing05;
		&:last-of-type {margin-right: 0px;}
		*/
		
		a {
			
			
			@include f_aside;
			/* TEXT */
			text-transform: uppercase; 
			color: $c_white;
			// font-weight: bold; // TEMP 
			font-size: 16px;
			
			/* LAYOUT */
			display: block;
			// padding: $spacing0125 $spacing0125;
			padding: $spacing0125 3px;
			
			
			// @extend %ui_underline_simple;
			
			
			
			position: relative;			
				
				&::after {
					content: '';
			
					// height: 2px; // TEST 
					height: 1px; // TEST 
					// background-color: red;
					background-color: none;
					
					width: 100%; 
					position: absolute;
					left: 0px;
					bottom: -2px;
					@include transition(background-color, 0.2s);
					}
				
				// hacerlo comn onmouseneter & onmouseleave de jqwuery, no con ekl hover porque hay glitch !!!!
				/*
					&:hover::after {
					bottom: -6px; // hace width gllitch... 
					height: 3px; 
					}	
					*/
					&:hover::after,
					&.is-active::after {
					background-color: $c_white;
					}	
		
		
					
			// TODO - CUSTOM - UNDERLINE
			/*
			&:hover,
			&.is-active {
				// @extend %ui_underline_simple;
				}
			*/
			
			} // a
						
		} // li 
	

	
	@media only screen and (max-width: $bp_m) { 
	
		li { 
			float: none; // RESET
		
			&:first-of-type {
				margin-right: 0px; // RESET
				margin-bottom: $spacing05; // TEST
				}
					
			} // li 
				
					
		} // MEDIA-QUERY	
	

	
} // nav.main
	
