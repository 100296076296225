/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Footer
 */
 
 
// WP LANGUAGFE SELECTOR ...

.wpml-ls-display {
	display: none;
	}
 
.lang-case-no {
	
	.wpml-ls-item-no {display: none !important;}
	
	} // MY CODE
	
	
.lang-case-en {
	
	.wpml-ls-item-en {display: none !important;}
	
	} // MY CODE 
	
	
	
 
 
footer {
	
	.inner {
		@extend .clearfix;
		// padding: $spacing05;
		// @include paddingv($spacing); // paddingh managed by frame
		@include paddingv($spacing2);
		@include paddingv(36px);
		}
			
	} // footer




.w_gridFooter {
	display: flex;
	// justify-content: flex-end;
	// justify-content: center;
	justify-content: space-between;
	flex-wrap: wrap;

	@media only screen and (max-width: $bp_m) { 
		justify-content: flex-start;	
		} // MEDIA-QUERY
			
			
	@media only screen and (max-width: $bp_s) { 
		display: block;
		} // MEDIA-QUERY	
			

	.col {

		@media only screen and (max-width: $bp_l) { 

// https://stackoverflow.com/questions/41789278/first-child-full-width-in-flexbox/41789386
// You can set the :first-child to a width of 100%, and the rest of the childs :not(:first-child) to flex: 1. To put them on multiplelines, use flex-wrap: wrap on the container:
		
		// flex: 1;
		

			&:nth-of-type(1) {
				width: 100%;
				// width: 0 0 100%;
				// border: 1px solid red;
				margin-bottom: $spacing; // TEST
				} // 

			} // MEDIA-QUERY

					
		@media only screen and (max-width: $bp_m) { 
		
			&:nth-of-type(4) {
				width: 100%;
				// width: 0 0 100%;
				margin-top: $spacing; // TEST
				} // 	
				
			justify-content: flex-start;
			
			&:nth-of-type(2) {
				// margin-right: $spacing; // TEST
				// margin-right: 36px; // TEST
				margin-right: $spacing2; // TEST
				} // 
						
			} // MEDIA-QUERY
			
			
		@media only screen and (max-width: $bp_s) { 
		
			&:nth-of-type(2) {
				margin-left: 0px; // RESET
				margin-bottom: $spacing; // TEST
				} // 
						
			} // MEDIA-QUERY
			
			
						
		/*
		@media only screen and (max-width: $bp_m) { 
			@include fullwidth;	
			margin-right: 0%; // RESET
			} // MEDIA-QUERY
		*/	
			
		} // col
	

	
	
	.text {
		
		
		// @include f_main_light; // ppero tendria que ser es el beausite fit !!! 
		@include f_aside; // TEST
		
		color: white;
		
		// font-weight: bold; // TEMP
		
		line-height: 1.6rem; // TEST HACK
		a {
			color: inherit; 
			&:hover {text-decoration: underline;}
			
			} // a
		
		
		ul {}
		
		
		li {}	
		
		
		
		a.a-linkedin {display: none;}	
		
			
		} // text
		
		
		
			
		@media only screen and (max-width: $bp_s) { 
			
			.text {
				a.a-linkedin {display: block;}	
				} // text	
			
					
			} // MEDIA-QUERY
			
			
			
	} //w_gridFooter 



nav.footer {
	
	// border: 1px solid red; 
	
	} // 


.socialFooter {
	margin-top: 16px;
	
	ul {display: flex;}
	
	li {
		margin-right: $spacing075;  
		&:last-of-type {margin-right: 0;}
		
		img {display:block;}
		
		} // li


		@media only screen and (max-width: $bp_s) { 
			display: none;		
			} // MEDIA-QUERY
			
		
			
} // socialFooter




			
			
	