 
 
 
 
 
 
.m_fullpage {
	height: 100vh; // TEST
	/* BG IMG */
	@include bg_img;
	
	background-color: #070707; // /* PRECAUTION */
	
	@media only screen and (max-width: $bp_m) { 
		
		&.case-expertise {
			background-size: contain; // but only good for case expertise...
			
			} // 	
			
		} /* MEDIA-QUERY */		
				








	// FLEX - PARENT - VERTICALLY CENTERED CONTENT
	// https://philipwalton.github.io/solved-by-flexbox/demos/vertical-centering/ 
	display: flex;
	align-items: center; // VERTICAL
	justify-content: center; // HORIZONTAL	
		
	.fullpageContent {
		text-align: center; // FROM THE START. ONLY BECAUSE 404 PAGE WANTS TO START IN 2 LINES
		
		// 404 PAGE
		a {
			color: inherit;
			
			// BUT DO CUSTOM EBORDER !!
			text-decoration: underline;
			&:hover {text-decoration: none;}
			}
		
		
		.text {
			/* TEXT */
			// text-align: center;
			@include f_main_light;
			color: $c_white;
			font-size: 49px; // TEMP
			
			span { }
				
			@media only screen and (max-width: $bp_m) { 
				text-align: center;
				
				span {
					display: block;
					margin-bottom: $spacing; // TEST
					&:last-of-type {margin-bottom: 0px;}
					}
					
				} /* MEDIA-QUERY */		
			
			} // text
		
		
		
		
		} // fullpageContent
		
		
		
	} // m_fullpage
	
	
	
	
	
	
	
	
	
.m_postText {
	// border: 10px solid red;

	// @include paddingv($spacing3); // TEST	/ specifically for page expeticse
  padding-top: $spacing4;
	padding-bottom: $spacing6;
	// padding-bottom: $spacing7;
	
		
	// border: 1px solid red;
	
	&.case-expertise {
		padding-left: $spacing8; // TEST
		// padding-right: $spacing4; // TEST - wont work like this // TODO !!!!!!!!!!!!!!
		} // case-expertise
	
	
	// @include f_aside; // TEST
	@include f_aside_light; // TEST
	p {
		// border: 1px solid red;
		// @include f_main_light;
		@include f_aside_light; // TEST
		font-size: 18px;
		line-height:1.6rem;
		
		margin-bottom: $spacing05; // TEST
		
		
		&:first-of-type {
			// @include f_main;
			@include f_aside; // TEST
			font-size: 21px;
			line-height:2rem;
			margin-bottom: $spacing2;
			}
		
		} // p
	
	
	} // m_postText	