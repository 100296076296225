/* 
 * File: Cookies - Wordpress Plugin
 *
 */
 
 
// EL TENER EL POPUP, CAUSA EL ESPACIO ESE VACIO DE ARRIBA, COMPROBADO..

/*
.ctcc-inner  {
	border: 2px solid red; // dev
	
	height: 200px !important; 
	}
*/



.has-cookie-bar #catapult-cookie-bar {
					opacity: 1;
					max-height: 999px;
					min-height: 30px;
	
	min-height: 0px !important; // RESET
	
	
	// border: 1px solid red;
				}



#catapult-cookie-bar {
	// display: none; // ok, pero queda otra cosa ahi visible .. wtf is it ?? y entra con animacion ... 
	
	/* HACK POSITIONING */
	// background-color: red !important;
	
	// sposition: relative !important;
	// position: absolute !important;
	// NOPE, IT MUST REMAIN FIXED AS IT ALREADY COMES, BUT BOTTOM ... 
	
	/*
	top: auto !important; // RESET (NO HAY EDIT OPTIONS EN EL CMS) 
	bottom: 0px; // WAS ENOUGH
	*/
	// bottom: 0px !important;
	
	// height: 5px;
	
	
	/* 
	background-color: $c_bg_main !important;
	color: $c_blue !important; // BLUE TEXT
	border-bottom: 2px solid $c_blue; // BLUE TEXT
	 */
	background-color: $c_white !important;
	color: $c_blue !important; // BLUE TEXT
	border-bottom: 2px solid $c_blue; // BLUE TEXT
	
	// @include paddingv();
	// padding: 8px 24px !important;
	
	
	a {
		// color: inherit;
		/* color: $c_blue !important; // BLUE TEXT */
		color: $c_blue !important; // BLUE TEXT
		text-decoration: underline;
		&:hover {text-decoration: none;} 
		} // a
	
	button {
		background-color: $c_blue !important; // BLUE TEXT
		color: #fff !important;
		@include transitionGeneral;
		&:hover {
			background-color: lighten($c_blue, 10%) !important; 
			}
		} // button
	
	
	
	} // catapult-cookie-bar
	
	
	
	
	
	
	
	

.has-cookie-bar #catapult-cookie-bar { // ".has-cookie-bar" se asigna por script, osea que hay "FOUC"
	// background-color: red; /* TEST */
	// background-color: $c_bg_main !important;
	/*
	background-color: $c_bg_main;
	color: #525F98; // LBUE TEXT
	border-bottom: 2px solid #525F98; // LBUE TEXT
	*/
	
	
	/*
	border: 5px solid #525F98;
	height: 0px !important;
	*/
	
	} // catapult-cookie-bar
  
  
  
