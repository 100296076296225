
.w_gridBase {
	/* LAYOUT */
	padding-top: $spacing4;
	padding-bottom: $spacing6;

	@media only screen and (max-width: $bp_m) {
		padding-top: 0px; // RESET		
		} /* MEDIA-QUERY */
			
	@media only screen and (max-width: $bp_s) { 
		padding-bottom: 0px;
		} /* MEDIA-QUERY */	
		
		
					
// ojo, case hero top e, grid base 1st colum vacio, tiene que sesapadec er que hace heigth.. solvable with flex... 			
				
				
				
	/* GRID */
	@extend .clearfix; // FLOATS ...

	.col {
		float: left;
		} // col
	
	
	&.case-single-staff { // BEHAVES AS THE HERO TEXT THINGE "w_heroStaff"
		// border: 3px solid red;
		
		.col {
	
			&:first-of-type {
				width: 32%;
				margin-right: 2%;
				} // first
				
			&:last-of-type {width: 66%;} // last
				
			@media only screen and (max-width: $bp_m) {
					
				&:first-of-type {
					@include fullwidth;
					margin-right: 0%; // RESET
					// margin-bottom: $spacing; // TEST
					margin-bottom: $spacing2; // TEST
					} // first
					
				&:last-of-type {
					@include fullwidth;
					
					
					// border: 1px solid red;
					} // last
							
				} /* MEDIA-QUERY */
				
					
			@media only screen and (max-width: $bp_s) {
				
				} /* MEDIA-QUERY */
					
			} // col
			
		
		
		
		} //case-single-staff 
		
		
	&.case-single-post { // WHEN THIS SI DOEN, APPLY IT TO THW EHEADER SCENARIO ... 
		// border: 3px solid yellow;
		
		.col {
	
			&:first-of-type {
				width: 23.5%;
				margin-right: 2%;
				} // first
				
			&:last-of-type {
				width: 74.5%;
				padding-right: $spacing8;
				} // last
			
			@media only screen and (max-width: $bp_l) {
					
				&:last-of-type {
					padding-right: 0px; // RESET
					} // last
		
				} /* MEDIA-QUERY */
								
			@media only screen and (max-width: $bp_m) {
					
				&:first-of-type {
					@include fullwidth;
					margin-right: 0%; // RESET
					// margin-bottom: $spacing2; // TEST
					margin-bottom: $spacing3; // TEST
					} // first
					
				&:last-of-type {@include fullwidth;} // last
							
				} /* MEDIA-QUERY */
					
			@media only screen and (max-width: $bp_s) {
				
				} /* MEDIA-QUERY */
					
			} // col
			
			
		} //case-single-post

	
	
	
		
	} // w_gridBase
	
	
	
	
	
/* LEFT COLOUMN */






// USE FLEX, FOR LATERAKL TO BE FIXED, AND THE REST BE SQUISSHY..

.memberPhoto {
  /* LAYOUT */
	margin-top: -210px; // TEST
	max-width: 80%; // TEST
	
	
	margin-bottom: 36px;
	
	
	// img {@include img;}
	/* BG IMG */
	@include bg_img;
	// padding-top: 131%; // 
	padding-top: 104.54%; // 460x440px
	} // memberPhoto


@media only screen and (max-width: $bp_m) { 
	
.memberPhoto {

	margin-top: 0px; // RESET
	max-width: 80%; // TEST
	
	
	
	
	// margin-bottom: $spacing05;
	margin-bottom: 8px;
	
	} // memberPhoto	
					
				
} // MEDIA-QUERY



@media only screen and (max-width: $bp_s) { // change img proportion as well !!! ponerlo como estña en el grid en pequeño...
	
.memberPhoto {
	margin-top: 0px; // RESET
	max-width: 100%; // RESET
	/* BG IMG */
	padding-top: 74.67%; // 375x280px // copied form staf fgrid sceanrio, i didnt really check teh exact proportiosn oin the individual staff page (mobiel version)	
	} // memberPhoto	
					
					
} // MEDIA-QUERY







.memberProfile {
	/* LAYOUT */
	// margin-top: 36px; // TEST - mejor que se margin bototm de la imagen, para el page post donde no hay la imagen
	/* TEXT */
	color: $c_blue;
	font-size: 19px; 
	@include f_aside; // TEST
	// border: 1px solid red; 
	
	
	@media only screen and (max-width: $bp_s) { 
		margin-top: 24px; // TEST
		@include paddingh($spacing);			
		} // MEDIA-QUERY


		
	.item {
		// border: 1px solid red; 
		} // item	
		
		

	a {
		color: inherit;
		&:hover {text-decoration: underline;} // BUT CUSTOMIZED !!!
		}
	
	li { // USED FOR  contact & articles
		margin-bottom: $spacing0125; // TEST
		&:last-of-type {margin-bottom: 0px;}
		} // li
	
	.separator { // COULD BE PSEUDOK ELEMENT AFTER
		// @include marginv($spacing); // TEST
		@include marginv(36px); // TEST
		@include marginv(32px); // TEST
		height: 2px; // ISSUE... 
		width: 40px;
		background-color: $c_blue;
		}
	
	.title {
		font-size: 24px; // OK
		margin-bottom: $spacing025; // OK
		}
		
		
		
	@media only screen and (max-width: $bp_s) { 


		.item {
	
			&:nth-of-type(5) { // HIDE ARTIDLES
				display: none;
				} // nth
			
			} // item	
			
		.separator {
				// border: 1px solid red;
				@include marginv(18px); // TEST

				&:nth-of-type(4) { // HIDE ARTIDLES
					display: none;
					} // nth

				} // separator
		
				
		} // MEDIA-QUERY
		
		
			
		
	} // memberProfile
	
	
		
		
		
		
		
	
/* RIGHT COLUMN */	
.memberText {
	// border: 1px solid red; 
	
	/* LAYOUT */
	margin-bottom: $spacing2; // TEST, same same
	// ADD APDDING-RIGHT -.....  // TODO
	
	padding-right: $spacing2; // TEST HACKY TODO
	
	/* TEXT */
	font-size: 22px; // TEST
	line-height: 1.9rem; // TEST
	
	@media only screen and (max-width: $bp_l) { 
		padding-right: 0px; // RESET				
		} // MEDIA-QUERY	

	@media only screen and (max-width: $bp_s) { 
		@include paddingh($spacing);	
		
		
		// HACKY START
		padding-top: $spacing2; 		
		} // MEDIA-QUERY
			
	} // memberText









.memberBlock {
	
	
	
	
	
	&.case-articles { // HIDE & SHOW
		display: none;
		@media only screen and (max-width: $bp_s) {display: block;} // MEDIA-QUERY		
		} // case-articles


	// border: 1px solid green;

	margin-bottom: $spacing2; // TEST, same same
	
	// text culd be here inside... nicer system.. 
	
	
	@media only screen and (max-width: $bp_s) { 
		@include paddingh($spacing);			
		} // MEDIA-QUERY
		
		
	
	/* TEXT */
	// @include f_main_light;
	@include f_aside_light; // TEST
	font-size: 18px; // TEST
	line-height: 1.6rem;
			
	.title {
		/* LAYOUT */
		// margin-bottom: $spacing025; // TEST		
		margin-bottom: $spacing05; // TEST			
		/* TEXT */
		@include f_main; // RESET
		color: $c_blue;
		font-size: 24px; // TEST
		} // title
	

	.positions {
		// border: 1px solid red;
		
		li {
			margin-bottom: $spacing05; // TEST
			&:last-of-type {margin-bottom: 0px;}
			
			// display: flex; // FLEX 
			@extend .clearfix;
			
			.years {
				// border: 1px solid red;// !! BORDER DOES THE TRICK - HACKY - but test 3 line test what happens - ah, se rompe, of course
				// so must buiold this failproofe, with felx .
				float: left;
				width: 140px;
				}
			.role {
				float: left;
				width: calc(100% - 140px);
				}
				
			} // li

		} // positions
	
	
	
	
	.competences {
		
		li {
			display: inline-block;

			&::after {
				content: ' -';
				} // after
			
			&:last-of-type::after {
				content: ''; // RESET
				} // after
			
			} // li
					
			
		} // competences
		
		
		
		
		// UTDANNING ...
		
		
	.articles {
		/* TEXT */
		color: $c_blue;
		font-size: 19px; 


		@include f_main;

		a {
			color: inherit;
			&:hover {text-decoration: underline;} // BUT CUSTOMIZED !!!
			}
		
		li { // USED FOR  contact & articles (this is copy paste)
			margin-bottom: $spacing0125; // TEST
			&:last-of-type {margin-bottom: 0px;}
			} // li
	
		} // articles
		
		
		
		
					
	
	.list {
		
		ul {
			// list-style: disc; // !!!!!!!!
			margin-left: 24px; // ...
			} // ul
		
		li {
			margin-bottom: $spacing05; // TEST
			// margin-bottom: $spacing075; // TEST
			&:last-of-type {margin-bottom: 0px;}
			
			position: relative;
			&::before {
				content: '•';
				position: absolute; 
				left: -24px;
				} // before
			
			} // li
		
		} // list
	
	
	
	@media only screen and (max-width: $bp_s) { 
		
		.positions {
			
			li {
				// margin-bottom: $spacing05; // TEST
				margin-bottom: $spacing075; // TEST
				&:last-of-type {margin-bottom: 0px;}
				
				
				.years {
					@include fullwidth;					
					}
				.role {
					@include fullwidth;
					}
								
				} // li
						
			} // positions
		
		
		.competences {
			
			li {
				display: block; // RESET
	
				&::after {
					content: ''; // RESET
					} // after

			
				// margin-bottom: $spacing05; // TEST
				margin-bottom: $spacing025; // TEST
				&:last-of-type {margin-bottom: 0px;}
				
				} // li
						

				
			} // competences
		
				
				
		} // MEDIA-QUERY
		
		
		
			
	} // memberBlock
	