.m_factsBlock {
	/* LAYOUT */
	
	margin-top: $spacing2;
	background-color: $c_grey; 
	padding: 36px $spacing;

	margin-left: -$spacing; // HACKY TEST
	@media only screen and (max-width: $bp_s) { 
		margin-right: -$spacing; // HACKY TEST
		
		} /* MEDIA-QUERY */	
				
				
	/* TEXT */
	color: $c_blue; 
	@include f_main_light;
	font-size: 18px; // TEST
	line-height: 1.6rem;
	
	.title {
		@include f_main
		font-size: 22px;
		margin-bottom: $spacing05; // TEST
		} // title
	
	
	
	// (ALMOST) same list style as the publications in 

		ul {
			// list-style: disc; // !!!!!!!!
			margin-left: 24px; // ...
			} // ul
		
		li {
			// margin-bottom: $spacing05; // TEST
			// margin-bottom: $spacing075; // TEST
			margin-bottom: $spacing025; // TEST
			&:last-of-type {margin-bottom: 0px;}
			
			position: relative;
			&::before {
				content: '•';
				position: absolute; 
				left: -24px;
				} // before
			
			} // li	
	} // m_factsBlock