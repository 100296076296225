@import "shared";

// Webkit, IE10 and future support for [CSS Regions](http://dev.w3.org/csswg/css3-regions/)
//
// $target is a value you use to link two regions of your css. Give the source of your content the flow-into property, and give your target container the flow-from property.
//
// For a visual explanation, see the diagrams at Chris Coyier's
// [CSS-Tricks](http://css-tricks.com/content-folding/)

@mixin flow-into($target) {
  $target: unquote($target);
  @include experimental(flow-into, $target,
    not(-moz), -webkit, not(-o), -ms, not(-khtml), not official
  );
}

@mixin flow-from($target) {
  $target: unquote($target);
  @include experimental(flow-from, $target,
    not(-moz), -webkit, not(-o), -ms, not(-khtml), not official
  );
}