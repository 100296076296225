/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: a grid ... 
 */



.w_heroStaff { // ANY NAME
	/* LAYOUT */
	// padding-top: $spacing4;
	padding-top: $spacing6;
	padding-bottom: $spacing2;
	
	
	
	
@media only screen and (max-width: $bp_m) { 
	
	padding-top: $spacing2;
	padding-bottom: 60px;			
						
} // MEDIA-QUERY	



@media only screen and (max-width: $bp_m) { 
	
	padding-top: $spacing2;
	padding-bottom: 60px;			
						
} // MEDIA-QUERY	








// CASE STAFF INDIVIDUAL PAGE, FOR RWD
.dummyCol {} // dummyCol
@media only screen and (max-width: $bp_s) { 
.dummyCol {display: none;} // dummyCol							
} // MEDIA-QUERY










	/* GRID */
	@extend .clearfix; // FLOATS ...
	
	.col {
		float: left;
		}
		
	// 2 CASES, THE GENERIC (USED TWICE) AND THEN TEH DIFFEREN TONE USED 1 IN 1 POST PAGE.. 
	
	&.case-staff { // BEHAVES AS THE HERO TEXT THINGE "w_heroStaff"
		// border: 3px solid red;
		
			.col {
		
				&:first-of-type {
					width: 32%;
					margin-right: 2%;
					} // first
					
				&:last-of-type {
					width: 66%;
					} // last
					
				
				@media only screen and (max-width: $bp_m) {
									
					&:first-of-type {
						@include fullwidth;
						margin-right: 0%; // RESET
						margin-bottom: $spacing; // TEST
						} // first
						
					&:last-of-type {
						@include fullwidth;
						} // last
								
					} /* MEDIA-QUERY */
					
						
				@media only screen and (max-width: $bp_s) {
					
					} /* MEDIA-QUERY */
				
					
							
				} // col		
				
		} // case-single-staff
	
	
	
	
	
	
	
	&.case-post { // 
		// border: 3px solid yellow;
		
			// padding-top: $spacing2; // right from the start
			padding-top: $spacing4; // right from the start
			padding-bottom: $spacing4; // right from the start
		
		
			@media only screen and (max-width: $bp_s) {
			
				padding-top: $spacing3; // right from the start
				padding-bottom: $spacing3; // right from the start
							
				} /* MEDIA-QUERY */
					
					
							
			.col {
		
				&:first-of-type {
					width: 23.5%;
					margin-right: 2%;
					} // first
					
				&:last-of-type {
					width: 74.5%;
					} // last
					
				
				@media only screen and (max-width: $bp_m) {
									
					&:first-of-type {
						@include fullwidth;
						margin-right: 0%; // RESET
						margin-bottom: $spacing; // TEST
						} // first
						
					&:last-of-type {
						@include fullwidth;
						} // last
								
					} /* MEDIA-QUERY */
					
						
				@media only screen and (max-width: $bp_s) {
					
					} /* MEDIA-QUERY */
				
					
							
				} // col		
				
				
				
		} // case-single-staff
		
		
		

	
	
	
	
	
	
	
	.heroStaff_main {
		color: $c_blue;
		// font-size: 48px;
		font-size: 50px;
		@include f_main_light;
		
		// case individual staff
		.member_name {}
		
		.member_role { 
			font-size: 22px; // TEST
			@include f_aside; // TEST
			} //member_role
		
		
		// CASE: single post
		
		.postSubject{
			font-size: 22px; // TEST
			// @include f_main;
			@include f_aside; // TEST
			margin-bottom: $spacing075; // TEST
			}
		
		
		} // heroStaff_main  
		
		
		
		
		
		
		
		
		
	.heroStaff_aside {
		padding-top: 12px; // HACKY - 
		font-size: 22px; // TEST
		
		// NOT QUITE FULLWIDTH... // TODO 
		
		
		@include f_aside; // TEST
		
		
		} // heroStaff_aside  
		
		
		
	} // w_gridStaff
	
	
	
	
.dummy {
	background-color: $c_grey; 
	
	height: 100px;
	position: absolute; 
	width: 100%;
	z-index: 0;
	} // dummy	
	
	@media only screen and (max-width: $bp_s) {
		.dummy {
			display: none;
			} // dummy	
			
		} /* MEDIA-QUERY */	







/*
INSTRUCTIONS FOR ANY GRID: 
separate grid behavior (wrapper & cols) from item behavior (and its own rwd behavior)
*/

.w_gridStaff { // ANY NAME
	position: relative; z-index: 1;

	margin-bottom: $spacing4; // TEST

	@extend .clearfix; // FLOATS ...
	
	/* MASONRY */
	// .gutter-sizer {width: 3%;} // 3 !!!
	
	
	.col {
		float: left;
		width: 32%; /* 3 COLUMNS */
		margin-right: 2%; // STANDARDIZE THIS THROUGHOUT 
		&:nth-of-type(3n) {margin-right: 0%;}
		
		// margin-bottom: 4%; // just a tad more
		// margin-bottom: 6%;
		// margin-bottom: 5%;
		// margin-bottom: $spacing2;
		margin-bottom: $spacing3;
		&:last-of-type {margin-bottom: 0%;} /* PRECAUTION */

		
		@media only screen and (max-width: $bp_m) {
			width: 49%;
			&:nth-of-type(3n) {margin-right: 2%;} // RESET
			&:nth-of-type(2n) {margin-right: 0%;}
			} /* MEDIA-QUERY */
			
			
		@media only screen and (max-width: $bp_s) {
			@include fullwidth; // RESET
			margin-right: 0%; // RESET
			
			
			margin-bottom: $spacing2; // TEST
			// &:last-of-type {margin-bottom: 0px;}
			
					
			} /* MEDIA-QUERY */
				
		} // col
	
	
	
	
	} // w_gridStaff
	
	
	
	
	
	
	
	
	
	
	// TOTALLY SEPARATE, THE ITEMS, THE MODULES N
	
	// m_ = STANDALONE, location agnsotic
	
	.m_itemStaff {
		// box-shadow: 0px 0px 2px #ccc;
		// padding: $spacing025;
		
		
		.image {
			// OPTION A - IMAGE
			// img {@include img;}
			
			// OPTION B - BACKGORUND IMAGE
			
			@include bg_img;
			/*
			background-position: center center;
			background-repeat: no-repeat; 
			background-size: cover;
			// background-size: contain;
			*/
			// padding-top: 131%; // 
			padding-top: 104.54%; // 460x440px
			
			background-position: top center;
			
			// @include ar_4_3;
			background-color: #ccc; // TEMP PLACEHOLDER
			
			position: relative;
			.imageCover {
				position: absolute; top: 0px; width: 100%; height: 100%;
				a {display: block; height: 100%;}
				
				/* UI */					
				@include transitionGeneral;
				background-color: rgba(0,0,0,0.0);
				&.is-active {background-color: rgba(0,0,0,0.1);} // is-active
				
				} // imageCover
			
			
			
			
			} // image
		
		
		
		
		
		
		
		.info {
			/* DEV */
			// border: 1px solid red; 
			
			
			/* LAYOUT */
			// padding-top: $spacing;
			padding-top: 36px;
			/* TEXT */
			color: $c_blue;

			
			a, a:visited, a:hover {
				color: inherit;
				}
			
			@include f_aside; // TEST
			
			.name {
				margin-bottom: $spacing075; // TEST
				/* TEXT */
				font-size: 26px;
				// line-height: 34px; // = ???
				
				@include f_main; // TEST
				
				
				a:hover,
				a.is-active {
					text-decoration: underline;
					} 
					
					
					
				} // title
			
			
			.role,
			.contact {
				/* TEXT */
				font-size: 19px;
				line-height: 23px; // = ???
				} // role & contact
				
			.role {
				margin-bottom: $spacing0125; // TEST
				} // text
			
			.contact {
				/*
				@include transitionGeneral; // ??? 
				a:hover {
					text-decoration: underline;
					} 
				*/
				
				span { // MAYBE HIDE IN MOBILE ETC ... 
					display: inline-block;
					@include marginh(10px);
					}
				
				
				
				
				} // contact
			
			
			} // info
			
			
			
			
			
			
	@media only screen and (max-width: $bp_s) {
		

		
		
		.image {
			padding-top: 74.67%; // 375x280px
			// PERO SON DIEFERENTE SIMAGENES EN AMBOS CASOS... HMMM 
			
			
			background-position: center center; // RESET
			
			
			} // image
			
		.info {
			@include paddingh($spacing);	
			// etc
			
			
			
			} // info
			
					
			
		} /* MEDIA-QUERY */	
		
		
					
		} // m_itemXYZ
		
		
		
		